<template>
  <b10-list-item
    :item="formattedItem"
  >
    <v-list-item-avatar>
      <div
        class="text-xs-center"
      >
        <v-avatar
          :color="formattedItem.avatarColor"
        >
          <span class="white--text font-weight-bold subtitle-1">{{ formattedItem.avatar }}</span>
        </v-avatar>
      </div>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        {{ formattedItem.title }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ formattedItem.subtitle }}
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        {{ formattedItem.subtitle2 }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </b10-list-item>
</template>

<script>
import _ from '@/utils/lodash'
import { RONDA } from '@/utils/consts'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      RONDA,
    }
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      if (item.ffin) {
        item.title = `
          ${item.mronda_descripcion}
          de ${this.$options.filters.shortTime(item.fcomienzo)}
          a ${this.$options.filters.shortTime(item.ffin)}`
      } else {
        item.title = `${item.mronda_descripcion} desde las ${this.$options.filters.shortTime(item.fcomienzo)}`
      }
      item.subtitle = `${item.puesto_servicio_descripcion} - ${item.servicio_descripcion}`
      item.subtitle2 = `${this.$options.filters.shortDate(item.fcomienzo)}`
      item.avatar = this.$options.filters.initials(item.cliente_nombre)
      if (item.estado === RONDA.estados.enCurso) {
        item.avatarColor = RONDA.colores.enCurso
      } else {
        item.avatarColor = RONDA.colores.finalizada
      }
      return item
    }
  }
}
</script>
